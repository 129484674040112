import React, { useEffect, useState } from 'react';
import Social from '../components/Social';
import Layout from '../components/Layout';
import SEO from '../components/SEO';
import Instagram from '../assets/images/icons-insta-white.svg';
import Facebook from '../assets/images/facebook-logo-white.svg';
import linkedin from '../assets/images/icons-linkedin-white.svg';
import vegan from '../assets/images/veganlogo.png';
import health from '../assets/images/health4.png';
import Image from "gatsby-image";
import {Link, Trans, useTranslation, useI18next} from 'gatsby-plugin-react-i18next';
import { graphql } from "gatsby";
import parse from "html-react-parser";
import {isDesktop, isMobile, isTablet} from "react-device-detect";
import SocialInside from "../components/FooterInside";
import { Button, Modal } from 'react-bootstrap';
import "../assets/styles/vegan.css";

const VeganPage: React.FC = ({ data }) => {

    const { t } = useTranslation();

    const {language, changeLanguage} = useI18next();

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    let pageDetailsFull;

    let allWpPage;

    let allNews = data.allWpPost?.nodes;

    //  Check language
    if (language !== 'en') {

        pageDetailsFull = data.allWpPage?.nodes[0]?.pageDetails;


    }else{

        pageDetailsFull = data.allWpPage?.nodes[0]?.translations[0]?.pageDetails;

        let getEnglishList = [];
        data.allWpPost?.nodes.map((item, index) => {

            item['translations']['featuredImage'] = item.featuredImage;

            if(item.translations.length > 0){
                getEnglishList.push(item.translations);
            }

            allNews = getEnglishList;

        });

    }

    //  Sort the paper news
    allNews.sort(function(a, b){return b.reading.paperVolume - a.reading.paperVolume});

    // State for the list
    const [list, setList] = useState([...allNews.slice(0, 6)])

    // State to trigger oad more
    const [loadMore, setLoadMore] = useState(false)

    // State of whether there is more to load
    const [hasMore, setHasMore] = useState(allNews.length > 3)

    // Load more button click
    const handleLoadMore = () => {
        setLoadMore(true);

    }

    // Handle loading more articles
    useEffect(() => {
        if (loadMore && hasMore) {
            const currentLength = list.length
            const isMore = currentLength < allNews.length
            const nextResults = isMore
                ? allNews.slice(currentLength, currentLength + 3 )
                : []
            setList([...list, ...nextResults]);
            setLoadMore(false);
        }
    }, [loadMore, hasMore]) //eslint-disable-line

    //Check if there is more
    useEffect(() => {
        const isMore = list.length < allNews.length
        setHasMore(isMore)
    }, [list]);

    return (
        <Layout>

            <SEO title="Vegan" />
            <div className="body-wrap">

                <section className="content">

                    <div className="container mainHeaderPaper">

                        {
                            ((isDesktop || isTablet))
                                ?
                                <div className="row">

                                    <div className="col-9">

                                        <img style={{ textAlign: 'center', width: 200,  marginLeft: '55%', marginTop: '20px'}} src={vegan} />

                                        <h1 style={{ paddingLeft: '35%'}}>
                                            {
                                                (pageDetailsFull) && pageDetailsFull?.title
                                            }
                                        </h1>
                                    </div>
                                    <div className="col-3">
                                        <SocialInside styling={'mobileVersion'}/>
                                    </div>

                                </div>
                                :
                                <div className="row">

                                    <div className="row">

                                        <h1>{pageDetailsFull?.title}</h1>

                                    </div>

                                </div>
                        }

                        <div className="row">

                            <div className="col">

                                <Image
                                    fluid={data.allWpPage?.nodes[0]?.featuredImage?.node?.localFile?.childImageSharp?.fluid}
                                    alt={data.allWpPage?.nodes[0]?.featuredImage?.node?.localFile?.altText}
                                    className="img-fluid full"
                                />

                            </div>

                        </div>

                        {/*<div className="row justify-content-center titleDescription titleDescriptionEVT">*/}

                            {/*<div className="col-lg-8 col-sm-12 text-center">*/}

                                {/*<div style={{color: '#f14fae',*/}
                                    {/*'fontSize': '60px',*/}
                                    {/*'fontWeight': 'lighter',*/}
                                    {/*'marginBbottom': '20px',*/}
                                    {/*'letterSpacing': '-6px'}}>*/}
                                    {/*{*/}
                                        {/*(pageDetailsFull) && pageDetailsFull?.titleH3*/}
                                    {/*}*/}
                                {/*</div>*/}
                                {/*<p>*/}
                                    {/*{*/}
                                        {/*(pageDetailsFull) && parse(pageDetailsFull?.titleH3Description)*/}
                                    {/*}*/}
                                {/*</p>*/}

                                {/*/!*MODAL COMPETITION*!/*/}
                                {/*<Button style={{ backgroundColor: '#EA1792'}} className="compButton" onClick={handleShow}>*/}
                                    {/*Join us now*/}
                                {/*</Button>*/}

                                {/*<Modal show={show} onHide={handleClose}>*/}
                                    {/*<Modal.Header closeButton>*/}
                                        {/*<Modal.Title>Vegan Challenge</Modal.Title>*/}
                                    {/*</Modal.Header>*/}
                                    {/*<Modal.Body>*/}
                                        {/*<iframe src="https://docs.google.com/forms/d/e/1FAIpQLScdmEdBUFbwYvhpLUeakdaDW2vTZJvtTK-VPbuLKnaN7Q9wsw/viewform?embedded=true" width="640" height="2213" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>*/}
                                    {/*</Modal.Body>*/}
                                    {/*/!*<Modal.Footer>*!/*/}
                                        {/*/!*<Button variant="secondary" onClick={handleClose}>*!/*/}
                                            {/*/!*Close*!/*/}
                                        {/*/!*</Button>*!/*/}
                                        {/*/!*<Button variant="primary" onClick={handleClose}>*!/*/}
                                            {/*/!*Save Changes*!/*/}
                                        {/*/!*</Button>*!/*/}
                                    {/*/!*</Modal.Footer>*!/*/}
                                {/*</Modal>*/}

                            {/*</div>*/}

                        {/*</div>*/}

                    </div>

                </section>


                <section style={{marginBottom: '0'}} className="explore freshp">

                    <div className="container">

                        <div className="row">

                            <div className="col section-title">

                                <h2>
                                    reasons to go vegan
                                </h2>

                            </div>

                            <div className="article-rows">

                                <div className="row">

                                    <img style={{ textAlign: 'left', width: 1000, marginTop: '20px', marginLeft: 'auto', marginRight: 'auto'}} src={health} />

                                </div>
                            </div>

                        </div>

                    </div>

                </section>

                <section className="explore freshp">

                    <div className="container">

                        <div className="row">

                            <div className="col section-title">

                                <h2>
                                    {
                                        (pageDetailsFull) && pageDetailsFull?.titleSection
                                    }
                                </h2>

                            </div>

                        </div>

                        <div className="article-rows">

                            <div className="row">

                                { list.map((item, index) => {

                                    if (language !== 'en') {
                                        //console.log('index', index + 1, (index+1) % 3, (index+1) % 2, )
                                        return(

                                            <>
                                            { (((index + 1) % 3  === 1 && (index+1) % 2 === 1) || ((index + 1) % 3  === 0 && (index+1) % 2 === 0) )
                                                ?
                                                <div className={'col-lg-6 col-sm-12 ' + (((index+1) > 3) ? 'spacing-article' : '')}>

                                                    <Link to={item.link}
                                                          className="block-link">
                                                        <Image
                                                            fluid={item.newspaperImages ?.largeSize ?.localFile ?.childImageSharp ?.fluid}
                                                            alt={item.newspaperImages ?.largeSize ?.localFile ?.altText}
                                                            className="img-fluid full"
                                                        />
                                                        <div className="title-block large-block">
                                                            <h2>{item.title}</h2>
                                                            <h3>{item.reading.secondtitle}</h3>
                                                        </div>
                                                        <span className="more">{item.viewingCards.buttonText}</span>
                                                    </Link>

                                                </div>
                                                :
                                                <div className={'col-lg-3 col-sm-12 ' + (((index+1) > 3) ? 'spacing-article' : '')}>

                                                    <Link to={item.link}
                                                          className={(item?.viewingCards?.showBorder === 'Yes' || isMobile ) ? 'hover-link fixed-link' : 'hover-link'}
                                                    >
                                                        <div className="thumbwrap smallthumbwrap">
                                                            <Image
                                                                fluid={item.newspaperImages ?.smallSize ?.localFile ?.childImageSharp ?.fluid}
                                                                alt={item.newspaperImages ?.smallSize ?.localFile ?.altText}
                                                                className="img-fluid full"
                                                            />
                                                            <span className="more">{item?.viewingCards.buttonText}</span>
                                                        </div>
                                                        <h3>{item.title}</h3>
                                                        <p>{parse(item.reading.smallDescriptionArchiveListing?.substring(0,550)+"")}</p>
                                                        {/*<p><span className="read-more">{item?.viewingCards?.archiveMore}</span></p>*/}
                                                        <p style={{marginTop: '-15px'}}><span className="read-more">Read more</span></p>
                                                    </Link>

                                                </div>
                                            }

                                            </>

                                        );
                                    }else{

                                        return(

                                            <>
                                            { (((index + 1) % 3  === 1 && (index+1) % 2 === 1) || ((index + 1) % 3  === 0 && (index+1) % 2 === 0) )
                                                ?
                                                <div className={'col-lg-6 col-sm-12 ' + (((index+1) > 3) ? 'spacing-article' : '')}>

                                                    <Link to={item[0].link} className="block-link">
                                                        <Image
                                                            fluid={item?.newspaperImages ?.largeSize ?.localFile ?.childImageSharp ?.fluid}
                                                            alt={item.newspaperImages ?.largeSize ?.localFile ?.altText}
                                                            className="img-fluid full"
                                                        />
                                                        <div className="title-block large-block">
                                                            <h2>{item[0]?.title}</h2>
                                                            <h3>{item[0]?.reading?.secondtitle}</h3>
                                                        </div>
                                                        <span className="more">{item[0].viewingCards?.buttonText}</span>
                                                    </Link>

                                                </div>
                                                :
                                                <div className={'col-lg-3 col-sm-12 ' + (((index+1) > 3) ? 'spacing-article' : '')}>

                                                    <Link to={item.link}
                                                          className={(item?.viewingCards?.showBorder === 'Yes' || isMobile ) ? 'hover-link fixed-link' : 'hover-link'}>
                                                        >
                                                        <div className="thumbwrap smallthumbwrap">
                                                            <Image
                                                                fluid={item.newspaperImages ?.smallSize ?.localFile ?.childImageSharp ?.fluid}
                                                                alt={item.newspaperImages ?.smallSize ?.localFile ?.altText}
                                                                className="img-fluid full"
                                                            />
                                                            <span className="more">{item[0]?.viewingCards?.buttonText}</span>
                                                        </div>
                                                        <h3>{item[0]?.title}</h3>
                                                        <p>{parse(item[0].reading.smallDescriptionArchiveListing?.substring(0,550)+"")}</p>
                                                        {/*<p>*/}
                                                        {/*<span className="read-more">*/}
                                                        {/*<Link to={item[0].link} className="block-link">*/}
                                                        {/*{ item[0]?.viewingCards?.archiveMore }*/}
                                                        {/*</Link>*/}
                                                        {/*</span>*/}
                                                        {/*</p>*/}
                                                        <p style={{marginTop: '-15px'}}><span className="read-more">Read more</span></p>
                                                    </Link>

                                                </div>
                                            }

                                            </>

                                        );

                                    }

                                })}

                            </div>

                        </div>

                        <div className="row mt-5">

                            <div id="scrollMore" className="col text-center">

                                <button onClick={()=>{ handleLoadMore() }} className="load-more">{(pageDetailsFull) && pageDetailsFull?.archiveShowMorePosts}</button>

                            </div>

                        </div>

                    </div>

                </section>

            </div>

        </Layout>

    );
};

export default VeganPage;

export const query = graphql`
  query WordPressPostArchiveVegan($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    },
    allWpPost(
        sort: {fields: [date], order: DESC}
        filter: {categories: {nodes: {elemMatch: {databaseId: {in: [1073]}}}}}
      ) {
        nodes {
          title
          content
          link
          reading {
            minuteRead
            smallDescription
            smallDescriptionArchiveListing
            secondtitle
            paperVolume
          }
          viewingCards {
            buttonText
            externalUrl
            showBorder
            archiveMore
          }
          translations {
            title
            content
            link
            reading {
              minuteRead
              smallDescription
              smallDescriptionArchiveListing
              secondtitle
            }
            viewingCards {
              buttonText
              externalUrl
              showBorder
              archiveMore
            }
          }
          featuredImage {
            node {
              altText
              localFile {
                childImageSharp {
                  fluid(quality: 100, maxWidth: 579, maxHeight: 839) {
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                  }
                }
              }
            }
          }
          newspaperImages {
            largeSize {
              altText
              localFile {
                childImageSharp {
                  fluid(quality: 100, maxWidth: 579, maxHeight: 839, srcSetBreakpoints: [ 400, 579 ]) {
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                  }
                }
              }
            }
            smallSize {
              altText
              localFile {
                childImageSharp {
                  fluid(quality: 100, maxWidth: 280, maxHeight: 420) {
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                  }
                }
              }
            }
          }
        }
      },
      allWpPage(filter: {databaseId: {eq: 2369}}) {
        nodes {
          databaseId
          title
          pageDetails {
            title
            titleH3
            titleH3Description
            titleSection
            archiveShowMorePosts
          }
          featuredImage {
            node {
              altText
              localFile {
                childImageSharp {
                  fluid(maxWidth: 800, maxHeight:300, fit: COVER quality: 100, srcSetBreakpoints: [ 400, 600, 800 ]) {
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                  }
                }
              }
            }
          }
          translations {
            pageDetails {
              title
              titleH3
              titleSection
              titleH3Description
              archiveShowMorePosts
            }
          }
        }
      }
  },
`;